<template>
  <div>
    <h5 class="is-size-7 has-text-black has-text-weight-normal">Ingresa el ID / Código del curso que
      deseas
      editar</h5>
    <div class="is-flex w-100 is-align-items-center my-2">
      <input class="input" type="text" v-model="courseId">
      <button class="button is-warning ml-3 is-small" @click="findCourse()"> Buscar</button>
    </div>
    <div v-if="courseById">
      <div class="columns p-4">
        <div class="column">
          <h6 class=" is-size-7 has-text-grey-dark has-text-weight-semibold">
            Nombre del curso
          </h6>
          <b-input maxlength="100" type="text" v-model="courseById.curso.nombre"
                   size="is-small"
                   disabled>
          </b-input>
          <h6 class="has-text-grey-dark has-text-weight-semibold is-size-7 mt-4">
            Subcategoría
          </h6>
          <b-select :disabled="!courseById.categoria.hasOwnProperty('subcategory')" placeholder="Selecciona una sub-categoría" v-model="idSubcategorySelected"
                    size="is-small"
                    expanded @change.native="handleSubcategory()">
            <option v-for="subcat in courseById.categoria.subcategory " :value="subcat.id" :key="subcat.id">
              {{ subcat.name }}
            </option>
          </b-select>
        </div>
        <div class="column">
          <h6 class="has-text-grey-dark has-text-weight-semibold is-size-7">
            Categoría
          </h6>
          <b-select placeholder="Selecciona una categoría" v-model="idCategorySelected" size="is-small"
                    expanded
                    @change.native="handleCategory()">
            <option v-for="categoryList in categories" :value="categoryList.id"
                    :key="categoryList.id">
              {{ categoryList.name }}
            </option>
          </b-select>
          <div class="mt-4">
            <label class="is-size-7 mt-4">
              Imagen
            </label>
            <upload-image-modal :is-active="isActiveModal" :color="'warning'"
                                @uploadFileInfo="infoFile = $event"
                                @close="isActiveModal=false"></upload-image-modal>
            <div class="is-flex">
              <button class="button is-warning is-small mt-3" @click="isActiveModal = true">Subir
                imagen
              </button>
              <span class="is-size-7 mt-4 ml-3" v-if="infoFile">
                {{ infoFile.file.name }}
              </span>
              <span class="is-size-7 mt-4 ml-3" v-else>
                {{ courseById.curso.descripcion_imagen }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="is-flex is-justify-content-flex-end mt-4">
        <button class="button is-warning ml-3 is-small" @click="confirm()"> Guardar</button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";
import coursesService from "@/services/Courses";
import categoryService from "@/services/Category";

export default {
  name: "EditCourseAdminPanel",
  components: {
    UploadImageModal
  },
  data() {
    return {
      courseId: null,
      idToSearch: null,
      courseById: null,
      isActive: false,
      isActiveModal: false,
      infoFile: null,
      subcategoryList: null,
      category: null,
      subcategoryInit: null,
      subcategory: null,
      categories: [],
      idCategorySelected: null,
      idSubcategorySelected: null
    };
  },
  methods: {
    async getCategories() {
      this.categories = await categoryService.getCategoriesWhitSubcategory();
    },
    async findCourse() {
      try {
        await this.getCategories();
        this.courseById = await coursesService.findCourse(this.courseId);
        if (this.courseById.categoria.id){
          this.idCategorySelected = this.courseById.categoria.id;
          this.handleCategory();
        }
        if (this.courseById.curso.subcategory_id){
          this.idSubcategorySelected = this.courseById.curso.subcategory_id;
        }
        console.log('courseById', this.courseById);
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    handleCategory() {
      let category = this.categories.find((category) => {
          if( parseInt(category.id) === parseInt(this.idCategorySelected)){
            return category;
          }
        })
      ;
      this.courseById.categoria = category;
      this.courseById.subCategoria = category.subcategory;
    },
    async confirm() {
      await this.$store.dispatch("fetchLoading", true);
      if (this.infoFile) {
        try {
          const imageLink = await coursesService.uploadFile('imagesRecommendedCourse', 'imagesRecommendedCourse', this.infoFile.name, this.infoFile.file);
          await coursesService.updateCourseImage(this.courseId, {
            imageLink,
            description: this.infoFile.description,
          });
        } catch (error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger",
          });
          console.error(error);
        }
      }
      if (this.courseById.categoria) {
        try {
          await coursesService.updateCourseCategoria(
            this.courseId,
            this.idCategorySelected
          );
        } catch (error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error al actualizar la categoría",
            position: "is-bottom",
            type: "is-danger",
          });
          console.error(error);
        }
      }
      if (this.courseById.subCategoria) {
        try {
          await coursesService.updateCourseSubcategoria(
            this.courseId,
            this.idSubcategorySelected
          );
        } catch (error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error al actualizar la subcategoría",
            position: "is-bottom",
            type: "is-danger",
          });
          console.error(error);
        }
      }
      this.courseById = null;
      this.$buefy.toast.open({
        duration: 5000,
        message: "El curso fue modificado con éxito",
        position: "is-bottom",
        type: "is-success",
      });
      this.$store.dispatch("fetchLoading", false);
    },

  }
}
</script>

<style scoped>

</style>
