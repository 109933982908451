<template src="./ChooseDistrict.html"></template>

<script>

export default {
  name: "choose-district",
  data() {
    return {
      districts: [
        {
          id: 'general',
          name: 'General'
        },
        {
          id: 'academic',
          name: 'Académico'
        },
        {
          id: 'profile',
          name: 'Perfil'
        },
        {
          id: 'networking',
          name: 'Networking'
        },
        {
          id: 'jobs',
          name: 'Bolsa de Trabajo'
        },
        {
          id: 'entertaiment',
          name: 'Entretenimiento'
        },
        {
          id: 'events',
          name: 'Eventos'
        },
        {
          id: 'idi',
          name: 'I+D+I'
        },
        {
          id: 'mesa-tecnica',
          name: 'Mesa Técnica'
        }
      ],
      district: null,
    };
  },
  methods: {
    onChangeDistrict(event) {
      this.$store.dispatch("fetchDistrictSelected", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped src="./ChooseDistrict.scss"></style>