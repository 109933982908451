<template src="./AdminReports.html">

</template>

<script>
import ChooseDistrict from "../../../components/ChooseDistrict/ChooseDistrict";
import { mapGetters } from "vuex";
export default {
  name: 'admin-reports',
  components: {
    ChooseDistrict
  },
  data () {
    return {
      showAcademicDashboard: true,
      districtId: 'general'
    }
  },
  methods: {
    showViewNetworking() {
      this.showAcademicDashboard = true;
    }
  },
  computed: {
    ...mapGetters({
      district: "getDistrictSelected",
    }),
  },
  watch: {
    district: async function (value) {
      this.districtId = value;
    },
  },
}
</script>

<style lang="scss" scoped src="./AdminReports.scss">

</style>
