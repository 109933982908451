var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-reports has-background-white p-6"},[_c('div',{staticClass:"button-reports mb-4"},[_c('choose-district')],1),_c('div',{staticClass:"dashboard-reports"},[(_vm.districtId == 'general')?_c('div',[_vm._m(0)]):_vm._e(),(_vm.districtId == 'academic')?_c('div',[_vm._m(1)]):_vm._e(),(_vm.districtId == 'profile')?_c('div',[_vm._m(2)]):_vm._e(),(_vm.districtId == 'networking')?_c('div',[_vm._m(3)]):_vm._e(),(_vm.districtId == 'jobs')?_c('div',[_vm._m(4)]):_vm._e(),(_vm.districtId == 'events')?_c('div',[_vm._m(5)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"image is-1by1"},[_c('iframe',{staticClass:"has-ratio",attrs:{"width":"640","height":"360","src":"https://metabase.arrobamedellin.edu.co/public/dashboard/741e92a7-21cb-4d8e-816d-a1fa63a4a530","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"image is-1by1"},[_c('iframe',{staticClass:"has-ratio",attrs:{"width":"640","height":"360","src":"https://metabase.arrobamedellin.edu.co/public/dashboard/bec52bb8-52d2-4cf6-932a-ad3b702e593f","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"image is-1by1"},[_c('iframe',{staticClass:"has-ratio",attrs:{"width":"640","height":"360","src":"https://metabase.arrobamedellin.edu.co/public/dashboard/33aa3d20-a3a9-42e3-a160-7002de68eadd","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"image is-1by1"},[_c('iframe',{staticClass:"has-ratio",attrs:{"width":"640","height":"360","src":"https://metabase.arrobamedellin.edu.co/public/dashboard/9bdbff78-ff3a-4b44-8298-cdc96c890358","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"image is-1by1"},[_c('iframe',{staticClass:"has-ratio",attrs:{"width":"640","height":"360","src":"https://metabase.arrobamedellin.edu.co/public/dashboard/4104d815-6695-4cfe-bd6d-c66ae0a3e304","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"image is-1by1"},[_c('iframe',{staticClass:"has-ratio",attrs:{"width":"640","height":"360","src":"https://metabase.arrobamedellin.edu.co/public/dashboard/f731411b-b16e-4e97-85c7-501228dc7283","frameborder":"0","allowfullscreen":""}})])
}]

export { render, staticRenderFns }