import { render, staticRenderFns } from "./EditCourseAdminPanel.vue?vue&type=template&id=af2bad84&scoped=true&"
import script from "./EditCourseAdminPanel.vue?vue&type=script&lang=js&"
export * from "./EditCourseAdminPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af2bad84",
  null
  
)

export default component.exports