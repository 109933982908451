<template>
  <div>
    <div class="tabs is-small mt-3">
      <ul>
        <li :class="`${categorySubcategoryCourses === 'categories'? 'is-active' : ''}`"
            @click="setCategoriesSubcategories('categories')"><a>Categorías</a></li>
        <li :class="`${categorySubcategoryCourses === 'subcategories'? 'is-active' : ''}`"
            @click="setCategoriesSubcategories('subcategories')"><a>Subcategorías</a></li>
      </ul>
    </div>
    <table class="table is-fullwidth">
      <thead>
      <tr>
        <th class="is-size-7">Id</th>
        <th><abbr title="Position" class="is-size-7">Nombre</abbr></th>
        <th v-if="categorySubcategoryCourses !== 'categories' && edit"><abbr title="Position" class="is-size-7">Categoría</abbr>
        </th>
        <th class="is-size-7" v-if="categorySubcategoryCourses === 'categories'">
          Descripción
        </th>
        <th class="is-size-7" v-if="categorySubcategoryCourses === 'categories'">
          <abbr title="Played">Imagen</abbr>
        </th>
        <th class="is-size-7" v-if="categorySubcategoryCourses === 'categories'">Ícon</th>
        <th class="is-size-7 is-narrow"><abbr title="Won">Editar</abbr></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in coursesByCategoriesSubcategories" :key="index">
        <td class="is-size-7">{{ item.id }}</td>
        <td class="is-size-7">
          <b-input maxlength="100" type="text"
                   size="is-small" v-model="item.name" :disabled="!(edit && itemToEdit === index)">
          </b-input>
        </td>
        <td v-if="categorySubcategoryCourses !== 'categories' && edit && itemToEdit === index" class="is-size-7">
          <div>
            <b-select placeholder="Selecciona una categoría" v-model="item.categoryId" size="is-small"
                      expanded>
              <option v-for="categoryList in categories" :value="categoryList.id"
                      :key="categoryList.id">
                {{ categoryList.name }}
              </option>
            </b-select>
          </div>
        </td>
        <td class="is-size-7" v-if="categorySubcategoryCourses === 'categories'">
          <textarea class="textarea is-small" rows="3"
                    v-model="item.description" :disabled="!(edit && itemToEdit === index)">
          </textarea>
        </td>
        <td class="is-size-7" v-if="categorySubcategoryCourses === 'categories'">
          <img
            alt="Imagen de la categoría o subcategoria " height="180" width="160"
            :src="item.imagen" style="height: 100px">
          <div class="mt-4" v-if="edit && itemToEdit=== index">
            <upload-image-modal :is-active="isActiveModal" :color="'warning'"
                                @uploadFileInfo="infoFile1 = $event"
                                @close="isActiveModal=false"></upload-image-modal>
            <div class="is-flex is-flex-direction-column">
              <button class="button is-warning is-small mt-3" @click="isActiveModal = true">Subir
                nueva imagen
              </button>
              <span class="is-size-7 mt-4 ml-3" v-if="infoFile1">
                {{ infoFile1.file.name }}
              </span>
            </div>
          </div>
        </td>
        <td class="is-size-7" v-if="categorySubcategoryCourses === 'categories'">
          <div style="height: 105px"><img
            alt="Icono de la categoria o subcategoría" height="40" width="40"
            style="border-radius: 50%"
            :src="item.icon">
          </div>
          <div class="mt-4" v-if="edit && itemToEdit=== index">
            <upload-image-modal :is-active="isActiveModal" :color="'warning'"
                                @uploadFileInfo="infoFile = $event"
                                @close="isActiveModal=false"></upload-image-modal>
            <div class="is-flex is-flex-direction-column">
              <button class="button is-warning is-small mt-3" @click="isActiveModal = true">Subir
                nueva imagen
              </button>
              <span class="is-size-7 mt-4 ml-3" v-if="infoFile">
                {{ infoFile.file.name }}
              </span>
            </div>
          </div>
        </td>
        <td>
          <button v-if="edit && itemToEdit=== index" class="button is-warning is-small is-outlined my-1"
                  @click="edit = false; itemToEdit = null">
            Cancelar
          </button>
          <button v-if="edit && itemToEdit=== index" class="button is-warning is-small my-1"
                  @click="editCategorySubcategory(item)">
            Guardar
          </button>
          <button v-if="!edit" class="button is-warning is-small my-1"
                  @click="edit = true; itemToEdit = index">
            Editar
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";
import coursesService from "@/services/Courses";
import categoryService from "@/services/Category";

export default {
  name: "EditCategorySubcategory",
  components: {
    UploadImageModal,
  },
  data() {
    return {
      itemToEdit: null,
      categorySubcategoryCourses: 'categories',
      coursesByCategoriesSubcategories: [],
      isActive: false,
      isActiveModal: false,
      infoFile: null,
      infoFile1: null,
      edit: false
    };
  },
  methods: {
    async editCategorySubcategory(item) {
      if (this.categorySubcategoryCourses === 'categories') await this.editCategory();
      else await this.editSubcategory(item);
    },
    async editSubcategory(item) {
      await this.$store.dispatch('fetchLoading', true);
      try {
        await categoryService.updateSubcategory({
          name: item.name,
          categoryId: (item.categoryId).toString(),
        }, item.id);
        this.subcategoryName = null;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Subcategoría editada con éxito",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error al guardar la subcategoría",
          position: "is-bottom",
          type: "is-danger"
        });
        console.error(error);
      }
      await this.$store.dispatch('fetchLoading', false);
    },
    async editCategory(item) {
      await this.$store.dispatch('fetchLoading', true);
      try {
        await categoryService.updateSubcategory({
          name: item.name,
          description: item.description,
        }, item.id);
        this.$buefy.toast.open({
          duration: 5000,
          message: "categoría editada con éxito",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error al guardar la subcategoría",
          position: "is-bottom",
          type: "is-danger"
        });
        console.error(error);
      }
      await this.$store.dispatch('fetchLoading', false);
    },
    async getCategories() {
      this.categories = await categoryService.getCategoriesWhitSubcategory();
    },
    setCategoriesSubcategories(parameter) {
      this.categorySubcategoryCourses = parameter;
      this.getCategoriesSubcategories();
    },
    getCategoriesSubcategories() {
      if (this.categorySubcategoryCourses === 'categories') {
        this.getByCategories();
      } else if (this.categorySubcategoryCourses === 'subcategories') {
        this.getBySubCategories();
      }
    },
    async getByCategories() {
      try {
        this.coursesByCategoriesSubcategories = await coursesService.getByCategories()
      } catch (e) {
        console.log('e', e);
      }
    },
    async getBySubCategories() {
      try {
        this.coursesByCategoriesSubcategories = await coursesService.getBySubCategories()
      } catch (e) {
        console.log('e', e);
      }
    },
  },
  mounted() {
    this.getCategoriesSubcategories();
    this.getCategories();
  },
}
</script>

<style scoped>

</style>
