<template src="./AdminPanelAcademic.html"></template>

<script>
import coursesService from "@/services/Courses";
import categoryService from "@/services/Category";
import EditCourseAdminPanel from "@/components/EditCourseAdminPanel/EditCourseAdminPanel";
import EditCategorySubcategory from "@/components/EditCategorySubcategory/EditCategorySubcategory";
import JsonExcel from "vue-json-excel";
import AdminNetworking from "../Admin/AdminNetworking/AdminNetworking";
import AdminReports from "../Admin/AdminReports/AdminReports";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "AdminPanelAcademic",
  components: {
    EditCourseAdminPanel,
    EditCategorySubcategory,
    JsonExcel,
    AdminNetworking,
    AdminReports,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      courseId: null,
      idToSearch: null,
      tableDataPreEnroll: [],
      tableDataCategories: [],
      preEnrollData: [],
      keyWordPreEnroll: "",
      dataPreEnrollToShow: [],
      coursesByCategoriesSubcategories: [],
      preEnrollFilter: "all",
      categorySubcategoryCourses: "categories",
      courseById: null,
      isActive: false,
      isActiveModal: false,
      infoFile: null,
      subcategoryList: null,
      category: null,
      subcategoryInit: null,
      subcategory: null,
      categories: [],
      jsonExcel: [],
      academicView: true,
      networkingView: false,
      reportsView: false,
      role: null,
    };
  },
  methods: {
    async getCategories() {
      this.categories = await categoryService.getCategoriesWhitSubcategory();
    },
    async getCourse() {
      try {
        this.courseById = await coursesService.getCourse(this.courseId);
      } catch (e) {
        console.log("e", e);
      }
    },
    async searchByWord() {
      try {
        this.tableDataPreEnroll = await coursesService.getPreEnrollCourseByWord(
          this.keyWordPreEnroll
        );
      } catch (e) {
        console.log("e", e);
      }
      await this.getTableDataPreEnroll()
    },
    async getTableDataPreEnroll() {
      this.tableDataPreEnroll =
        await coursesService.getPreEnrollCourseDefault();
      const requirementsKeys = ["age", "location"];
      this.dataPreEnrollToShow = this.tableDataPreEnroll.map((elementArray) => {
        let elementData = {};
        Object.values(elementArray).map((section) => {
          if (Array.isArray(section)) {
            section.map((element) => {
              requirementsKeys.map((key) => {
                if (element.inputType === key) {
                  elementData[key] = element;
                }
              });
              elementData.userId = elementArray.userId;
              elementData.status = elementArray.status;
              elementData.codeCourse = elementArray.codeCourse;
              elementData.id = elementArray.id;
            });
          }
        });
        this.preEnrollData.push(elementData);
        return elementData;
      });
      this.getJsonExel();
    },
    flattenObject(obj) {
      let result = {};
      for (let key in obj) {
        if (typeof obj[key] === "object" ) {
          let flatObject = this.flattenObject(obj[key]);
          for (let subKey in flatObject) {
            result[key + "-" + subKey] = flatObject[subKey];
          }
        } else {
          result[key] = obj[key];
        }
      }
      return result;
    },
    async getJsonExel() {
      let data = [];
      if (this.keyWordPreEnroll !== "") {
        data = await coursesService.getPreEnrollCourseByWord(
          this.keyWordPreEnroll
        );
      } else {
        data = await coursesService.getPreEnrollCourseDefault();
      }
      let flattenedArray = await data.map(obj => this.flattenObject(obj));
      this.jsonExcel = flattenedArray;
    },
    setCategoriesSubcategories(parameter) {
      this.categorySubcategoryCourses = parameter;
      this.getCategoriesSubcategories();
    },
    showViewNetworking() {
      this.academicView = false;
      this.networkingView = true;
      this.reportsView = false;
    },
    showViewAcademic() {
      this.networkingView = false;
      this.academicView = true;
      this.reportsView = false;
    },
    showViewReports() {
      this.networkingView = false;
      this.academicView = false;
      this.reportsView = true;
    },
    redirectSupport() {
      window.open(
        "https://soporteciudadela.arrobamedellin.edu.co/dashboard/admin",
        "_blank"
      );
    },
    redirectAnalysisReporting() {
      window.open("https://metabase.arrobamedellin.edu.co/", "_blank");
    },
  },
  mounted() {
    this.getTableDataPreEnroll();
    this.role = this.$store.state.User.user.role.name;
  },
  computed: {
    filterPreEnrollData() {
      if (this.preEnrollFilter === "selected") {
        return this.dataPreEnrollToShow.filter((element) => {
          this.preEnrollData = [];
          if (element.status === "agree") {
            this.preEnrollData.push(element);
          }
        });
      } else if (this.preEnrollFilter === "nonSelected") {
        return this.dataPreEnrollToShow.filter((element) => {
          this.preEnrollData = [];
          if (element.status === "rejected") {
            this.preEnrollData.push(element);
            return element;
          }
        });
      }
      return this.dataPreEnrollToShow;
    },
    roleValidationNoAccess() {
      return [
        "Invited",
        "University",
        "AdministratorCall",
        "Entrepreneur",
        "Student",
        "Teacher",
      ].some((a) => a === this.role);
    },
  },
};
</script>

<style scoped lang="scss" src="./AdminPanelAcademic.scss">

</style>
